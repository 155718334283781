.ql-editor .td-video {
    display: block;
    max-width: 100%;
  }
  .ql-editor .td-video .td-quill-video-overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    -webkit-transition: background-color 1s ease;
    -moz-transition: background-color 1s ease;
    -o-transition: background-color 1s ease;
    transition: background-color 1s ease;
  }
  .ql-editor .td-video .td-quill-video-overlay:hover {
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    border: 1px dashed #444;
  }
  .ql-editor .td-video .td-quill-video-overlay.active {
    background-color: rgba(0,0,0,0.4);
    box-sizing: border-box;
    border: 1px dashed #444;
  }
  .ql-editor .td-video .td-quill-video-wrapper {
    position: relative;
    display: inline-block;
    margin: auto;
  }
  .ql-editor .td-video .td-quill-video-editing {
    padding: 1px;
    min-width: 300px;
    min-height: 150px;
    max-width: 100%;
  }
  .ql-editor .td-video .td-quill-video-toolbar-wrapper {
    position: absolute;
    width: 100%;
    text-align: center;
  }
  .ql-editor .td-video .td-quill-video-toolbar-wrapper .td-quill-video-toolbar {
    position: relative;
    top: -15px;
    width: auto;
    display: inline-block;
    padding: 5px 0px;
  }
  .ql-editor .td-video .td-quill-video-toolbar .td-quill-video-align-action {
    padding: 0 5px;
    background-color: #fff;
    cursor: pointer;
    color: #3b3e43;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
  .ql-editor .td-video .td-quill-video-toolbar .td-quill-video-left {
    border-left: 1px solid #dbdbdb;
  }
  .ql-editor .td-video .td-quill-video-toolbar .td-quill-video-right {
    border-right: 1px solid #dbdbdb;
  }
  .ql-editor .td-video .td-quill-video-toolbar .td-quill-video-center {
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
  }
  .ql-editor .td-video .td-quill-video-toolbar .td-quill-video-align-action:hover {
    color: #0FCED1; 
  }
  .ql-editor .td-video .td-quill-resize-nub {
    position: absolute;
    height: 12px;
    width: 12px;
    background-color: #fff;
    border: 1px solid rgb(119, 119, 119);
    box-sizing: border-box;
    cursor: nesw-resize;  
  }
  .ql-editor .td-video .td-align-left:after{ 
    content: "\ \21E4"; 
  } 
  .ql-editor .td-video .td-align-center:after{ 
    content: "\ \2194";
    position: relative;
    left: -2px;
    top: -1px;
  } 
  .ql-editor .td-video .td-align-right:after{
    content: "\ \21E5"; 
  }